import React from 'react';
import { FaAward } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { VscFolderLibrary } from 'react-icons/vsc';
import './about.css';
import ME from '../../assets/about_me_bw.jpg';

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="me" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>2+ Years Working</small>
            </article>
            {/* <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Clients</h5>
              <small>200+ Worldwide</small>
            </article> */}
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>20+ Completed</small>
            </article>
          </div>
          <p>
          I have over two years of hands-on experience in both front-end and back-end development, with a solid foundation in HTML, CSS, and JavaScript. My expertise extends to modern frameworks and tools, enabling me to develop efficient, scalable, and user-centric applications. Additionally, I have experience in machine learning, leveraging data-driven approaches to build intelligent solutions that enhance user experiences and solve complex problems.
          </p>
          <p>
          I am a highly motivated, results-driven, and fast learner who constantly seeks to enhance my skills and adapt to emerging technologies. My strong work ethic, combined with my ability to adapt quickly to change, allows me to excel in dynamic environments. I thrive in team settings as well as when working independently, always striving to deliver high-quality work on time, even under pressure.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
